<template>
  <div class="technology-page">
    <solution-head :list="headList" :active="headActive"></solution-head>
    <div
      :style="{ backgroundImage: 'url(' + showData.bg + ')' }"
      class="suoltion-top-infos"
    >
      <div class="cn-title">{{ showData.title }}</div>
      <div class="ev-title">{{ showData.enTitle }}</div>
    </div>
    <div class="contnet-list">
      <div v-for="(item, i) in showData.content" :key="i" class="contnet-item-for">
        <div v-if="item.type === 'describe'" class="content-item con-des">
          <div class="con-titile">{{ item.title }}</div>
          <div class="con-text" v-html="item.contnet"></div>
        </div>
        <div v-if="item.type === 'tableInfo'" class="content-item con-table">
          <div v-if="item.title" class="con-titile">{{ item.title }}</div>
          <div v-if="item.contnet" class="con-text">
            {{ item.contnet }}
          </div>
          <div class="con-table-img">
            <img :src="item.contnetImg" alt="" />
          </div>
        </div>
        <div v-if="item.type === 'iconInfo'" class="content-item con-icons">
          <div class="con-titile">{{ item.title }}</div>
          <div class="con-icon-list">
            <div class="icon-item" v-for="(item, i) in item.infos" :key="i">
              <img :src="item.icon" alt="" />
              <div class="icon-title">{{ item.title }}</div>
              <div class="icon-text">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.type === 'imgsInfo'" class="content-item con-imgs">
          <div v-if="item.title" class="con-titile">{{ item.title }}</div>
          <div v-if="item.contnet" class="con-text">
            {{ item.contnet }}
          </div>
          <div class="con-imgs-list">
            <div class="imgs-item" v-for="(item, i) in item.infos" :key="i">
              <img :src="item.url" alt="" />
              <div class="icon-title">{{ item.title }}</div>
              <div v-if="item.contnet || item.contnet !== ''" class="icon-text">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.type === 'imgsRowInfo'" class="content-item con-imgs">
          <div v-if="item.title" class="con-titile">{{ item.title }}</div>
          <div v-if="item.contnet" class="con-text">
            {{ item.contnet }}
          </div>
          <div class="con-imgs-row-list">
            <div class="imgs-item" v-for="(item, i) in item.infos" :key="i">
              <img :src="item.url" alt="" />
              <div class="icon-title">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div v-if="item.type === 'imgsDoubleInfo'" class="content-item con-imgs">
          <div v-if="item.title" class="con-titile">{{ item.title }}</div>
          <div v-if="item.contnet" class="con-text">
            {{ item.contnet }}
          </div>
          <div class="con-imgs-double-row-list">
            <div class="imgs-double-item" v-for="(item, i) in item.infos" :key="i">
              <img :src="item.url" alt="" />
              <div class="double-content">
                <div class="icon-title">{{ item.title }}</div>
                <div v-if="item.contnet || item.contnet !== ''" class="icon-text">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.type === 'tablesInfo'" class="content-item con-tables">
          <div v-if="item.title" class="con-titile">{{ item.title }}</div>
          <div v-if="item.contnet" class="con-text">
            {{ item.contnet }}
          </div>
          <div class="con-tables-arrow-list">
            <div class="tables-item" v-for="(citem, ci) in item.infos" :key="ci">
              <div class="tables-title">{{ citem.title }}</div>
              <div class="tables-content" :class="{ 'tables-arrow': item.showArrow }">
                <div
                  class="tables-item-content"
                  v-for="(titem, ti) in citem.content"
                  :key="ti"
                >
                  {{ titem }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.type === 'tablesInfoThree'"
          class="content-item con-tables con-tables-three"
        >
          <div v-if="item.title" class="con-titile">{{ item.title }}</div>
          <div class="con-tables-arrow-list">
            <div class="tables-item" v-for="(citem, ci) in item.infos" :key="ci">
              <div class="tables-title">{{ citem.title }}</div>
              <div class="tables-content" :class="{ 'tables-arrow': item.showArrow }">
                <div
                  class="tables-item-content"
                  v-for="(titem, ti) in citem.content"
                  :key="ti"
                >
                  <!-- {{ item.showArrow }} -->
                  {{ titem }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.type === 'cardsInfo'" class="content-item con-cards">
          <div v-if="item.title" class="con-titile">{{ item.title }}</div>
          <div v-if="item.contnet" class="con-text">
            {{ item.contnet }}
          </div>
          <div class="con-cards-infos">
            <div
              class="cards-item"
              v-for="(citem, ci) in item.infos"
              :key="ci"
              :style="{ width: item.width }"
            >
              <div class="cards-icon">
                <img :src="citem.icon" alt="" />
              </div>
              <div class="cards-info">
                <div class="card-title">{{ citem.title }}</div>
                <div class="card-text">{{ citem.content }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.type === 'oneImg'" class="content-item con-cards one-img">
          <div v-if="item.title" class="con-titile">{{ item.title }}</div>
          <div v-if="item.contnet" class="con-text">
            {{ item.contnet }}
          </div>
          <div class="con-cards-infos">
            <div
              class="cards-item"
              v-for="(citem, ci) in item.infos"
              :key="ci"
              :style="{ width: item.width }"
            >
              <div class="cards-icon">
                <img :src="citem.icon" alt="" />
              </div>
              <div class="cards-info">
                <div class="card-title">{{ citem.title }}</div>
                <div class="card-text">{{ citem.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <zy-footer></zy-footer>
  </div>
</template>

<script>
import rightIcon from '@/assets/image/technology/right.png';
import topBg from '@/assets/image/solution/spxxfxjjfa.png';
import solutionHead from '../../components/solutionPublicHead.vue';
import data from '@/utils/data';

export default {
  components: {
    solutionHead,
  },
  mixins: [data],
  data() {
    // 数据图片素材产品和
    return {
      topBg,
      rightIcon,
      // new
      pageType: '',
      showIndex: 0,
      proTab: {
        left: [
          {
            label: '视频信息分析解决方案',
            path: '/product/solution/product01',
          },
          {
            label: '直播信息分析解决方案',
            path: '/product/solution/product02',
          },
        ],
        right: [
          {
            label: '传播矩阵布局',
            id: '',
            path: '/product/solution/product03',
          },
          {
            label: '内容传播生产',
            id: '',
            path: '/product/solution/product04',
          },
          {
            label: '人机交互传播',
            path: '/product/solution/product05',
          },
        ],
        bottom: [
          {
            label: '跨境直播',
            path: '/product/solution/product06',
          },
          {
            label: '一站式跨境电商',
            path: '/product/solution/product07',
          },
          {
            label: '文娱严选',
            path: '/product/solution/product08',
          },
        ],
      },
      soTab: [],
    };
  },
  computed: {
    showData() {
      let data = {};
      if (this.pageType === 'product') {
        data = this.solutionChildData[this.showIndex];
      } else {
        data = this.productChildData[this.showIndex];
      }
      return data;
    },
    headList() {
      let data = [];
      if (this.pageType === 'product') {
        if (this.showIndex >= 5) {
          data = this.proTab.bottom;
        }
        if (this.showIndex < 5) {
          data = this.proTab.right;
        }
        if (this.showIndex < 2) {
          data = this.proTab.left;
        }
      } else {
        data = this.navData[2].children;
      }
      return data;
    },
    headActive() {
      let data = 0;
      if (this.pageType === 'product') {
        if (this.showIndex >= 5) {
          data = this.showIndex - 5;
        }
        if (this.showIndex < 5) {
          data = this.showIndex - 2;
        }
        if (this.showIndex < 2) {
          data = this.showIndex;
        }
      } else {
        data = this.showIndex;
      }
      return data;
    },
  },
  watch: {
    $route(to) {
      this.init(to);
    },
  },
  methods: {
    goDetail(e) {
      console.log(e, 'path');
    },
    init(value) {
      let root = value || this.$route;
      let v = root.params.type.split('/')[0];
      let types = v.split('0');
      this.pageType = types[0];
      this.showIndex = Number(types[1]) - 1;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.technology-page {
  background: #f2f2f2;
  .suoltion-top-infos {
    @extend .b-box;
    padding: 0 calc((100vw - 1160px) / 2);
    height: 300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 110px;
  }
  .con-des {
    @extend .f-cloum-center;
  }
  .con-text {
    width: 1160px;
  }

  .cn-title,
  .ev-title {
    @extend .mb20;
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
  }
  .cn-title {
    font-size: 36px;
  }
  .ev-title {
  }
  .contnet-item-for {
    &:nth-child(2n-1) {
      background: #ffffff;
      &:last-child {
        padding-bottom: 80px !important;
      }
    }
  }
  .content-item {
    @extend .f-cloum-center, .content-padding, .pt60, .pb60;
    flex-wrap: wrap;
    cursor: pointer;
  }
  .con-icons {
    padding-left: 0;
    padding-right: 0;
  }
  .con-tables {
    .con-text {
      @extend .mb30;
      text-align: center;
    }
  }
  .con-titile {
    @extend .pb30;
    text-align: center;
    font-weight: 600;
    font-size: 28px;
  }
  .con-text {
    line-height: 30px;
  }
  // 图标蚊子
  .con-icon-list {
    @extend .f;
    align-items: flex-start;
    justify-content: space-between;
    // width: 1160px;
  }
  .icon-item {
    @extend .f-cloum-center, .mr40;
    width: 100%;
    .icon-text {
      width: 260px;
    }
    img {
      width: 100px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .icon-title {
    @extend .mb10, .f20;
    font-weight: 500;
    color: #000;
  }
  .icon-text {
    line-height: 22px;
    width: 260px;
    text-align: center;
    color: #666;
  }

  // img 文字
  .con-imgs {
    padding-bottom: 0;
    .con-text {
      // width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .con-imgs-list {
    @extend .f-row-center-center;
    flex-wrap: wrap;
    align-items: flex-start;
    .icon-text {
      text-align: left;
    }
  }
  .imgs-item {
    @extend .f-cloum-center, .mb50;
    width: 360px;
    margin-right: 40px;
    &:nth-child(3n),
    &:last-child {
      margin-right: 0;
    }

    .icon-text {
      width: 100%;
    }
    img {
      @extend .mb20;
      width: 360px;
      height: 270px;
    }
  }
  .con-imgs-row-list {
    @extend .f-row-center-center;
    flex-wrap: wrap;
    align-items: flex-start;

    .imgs-item {
      @extend .f-cloum-center, .mb50;
      width: 260px;
      margin-right: 40px;

      .icon-text {
        width: 100%;
      }
      img {
        @extend .mb20;
        width: 260px;
        height: 195px;
      }
    }
  }
  .con-imgs-double-row-list {
    @extend .f-center;
    flex-wrap: wrap;
    // align-items: flex-start;

    .imgs-double-item {
      @extend .f-between, .mb50;
      width: 560px;
      margin-right: 40px;

      .icon-text {
        width: 260px;
        text-align: left;
      }
      img {
        @extend .mb20, .mr20;
        width: 260px;
        height: 195px;
      }
    }
  }

  .con-tables-list {
    @extend .f-row-center;
    .tables-item {
      @extend .b-box;
      width: 300px;
      padding-right: 40px;
    }
  }
  .con-tables-arrow-list {
    @extend .f-row-center;
    align-items: stretch;
    .tables-item {
      @extend .f-cloum, .b-box;
      width: 300px;
      padding-right: 40px;

      &:last-child {
        width: 260px;
        .tables-arrow::before {
          display: none;
        }
      }
    }
    .tables-title {
      @extend .b-box;
      width: 260px;
      height: 30px;
      text-align: center;
      background: #f0eefa;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #000000;
    }
    .tables-content {
      @extend .f-cloum-around, .p-relative;
      width: 258px;
      flex: 1;
      padding: 20px 0;
      text-align: center;
      background: #fff;
      border: 1px solid #eaeaea;
      border-top: 0;
      color: #666;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .tables-arrow {
      &::before {
        @extend .p-absolute;
        content: '';
        // background: ;
        // height: 100%;
        width: 40px;
        height: 8px;
        background-repeat: no-repeat;
        top: 50%;
        margin-top: -4px;
        right: -40px;
        background-image: url('~@/assets/image/solution/tables-arrow.svg');
      }
    }
  }
  .tables-item-content {
    line-height: 22px;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .con-tables-three {
    .tables-item {
      @extend .b-box;
      width: 400px;
      padding-right: 40px;

      &:last-child {
        width: 360px;
        .tables-arrow::before {
          display: none;
        }
      }
    }
    .tables-title {
      width: 360px;
    }
    .tables-content {
      width: 358px;
      justify-content: flex-start;
    }
    .tables-item-content {
      @extend .pr20, .pl20;
      text-align: left;
      // display: none;
    }
  }

  // table-img
  .con-table {
    text-align: center;
  }
  .con-table-img {
    @extend .pt40;
    img {
      width: 1160px;
    }
  }
}
.con-cards-infos {
  @extend .f-between;
  .cards-item {
    @extend .mr10, .f, .b-box;
    align-items: flex-start;
    width: 100%;
    padding: 30px 20px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  .cards-icon {
    width: 32px;
    margin-right: 15px;
    img {
      width: 36px;
      height: auto;
    }
  }
  .card-title {
    @extend .mb20;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
  }
  .tables-item-content {
    text-align: left;
  }
  .card-text {
    line-height: 22px;
    color: #666666;
  }
}
.one-img {
  .cards-icon {
    width: 260px;
    height: 195px;
    margin-right: 0;
    img {
      width: 260px;
      height: 195px;
    }
  }
  @extend .f-between;
  .cards-item {
    @extend .mr10, .f, .b-box;
    align-items: flex-start;
    padding: 0px;
    // background: ;
    border: 0;
    border-radius: 0px;
    &:last-child {
      margin-right: 0;
    }
  }
  .cards-info {
    height: 100%;
    @extend .f-cloum, .pr20, .pl40;
    // align-items: center;
    justify-content: center;
  }
  .card-title {
    @extend .mb20;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
  }
  .card-text {
    line-height: 22px;
    color: #666666;
  }
}
</style>
