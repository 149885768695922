<template>
  <div class="solution-head">
    <div class="solution-tabs">
      <div
        class="solution-tab"
        v-for="(item, i) in list"
        :key="i"
        :class="{ active: i == active }"
        @click="clickTab(item)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['list', 'active'],
  data() {
    return {
      showActive: 1,
    };
  },
  methods: {
    clickTab(item) {
      // this.showActive = item;
      console.log(item, 'item');
      this.$router.push(item.path);
    },
  },
};
</script>
<style lang="scss" scoped>
.solution-head {
  @include mt(90px);
  @include line-center(70px);
  @extend .content-padding;
}
.solution-tabs {
  @extend .f;
}
.solution-tab {
  @extend .mr40, .f18;
  height: 100%;
  cursor: pointer;
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #6e59d0;
    // border-radius: 2px;
    opacity: 0;
    transition: all 0.5s;
  }
  &:hover {
    color: #6e59d0;
  }
  // &:hover::before {
  //   opacity: 0.8;
  //   background: #6e59d0;
  // }
}
.active {
  &::before {
    opacity: 0.8;
  }
}
</style>
